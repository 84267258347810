import React, { useState, useEffect, useRef } from "react";
import { getSingleLoanEnquiry } from "../../../api/Enquiry/Loan";
import { useLocation } from "react-router-dom";
import Mastersidebar from "../../../compoents/AdminSidebar";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { RichTextEditor } from "@mantine/rte";
export const ViewLoanEnquiry = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [loan, setLoan] = useState(null);
  // Reference for the hidden anchor

  useEffect(() => {
    getLoanDetails();
  }, []);

  const getLoanDetails = () => {
    getSingleLoanEnquiry(id)
      .then((res) => {
        setLoan(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Mastersidebar />

      <div className="content-wrapper" style={{ fontSize: "14px" }}>
        <div className="content-header"></div>
        <div className="container-fluid">
          <div className="card  border-0 rounded-0 shadow-sm p-3 position-relative">
            <div
              className="card-header mt-3 border-0 rounded-end-pill position-absolute top-0 start-0"
              style={{ background: "#fe5722", color: "#fff" }}
            >
              <h5 className="text-center text-capitalize p-1">
                View Loan Enquiry Details
              </h5>
            </div>

            <div className="card-body ">
              <table
                className="table table-hover table-bordered table-striped-columns mt-5"
                style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}
              >
                <tbody>
                  <tr>
                    <td className="fw-bold">Student Name</td>
                    <td>{loan?.studentName || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Primary Number</td>
                    <td>{loan?.primaryNumber || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">WhatsApp Number</td>
                    <td>{loan?.whatsAppNumber || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Email ID</td>
                    <td>{loan?.email || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Do you have a valid offer from any university?
                    </td>
                    <td>
                      {loan?.doYouHaveAValidOfferFromAnyUniversity ||
                        "Not Available"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Loan Amount Required</td>
                    <td>{loan?.loanAmountRequired || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">What is your monthly income?</td>
                    <td>{loan?.whatIsYourMonthlyIncome || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Passport Number</td>
                    <td>{loan?.passportNumber || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Did you apply for loan elsewhere?
                    </td>
                    <td>
                      {loan?.didYouApplyForLoanElsewhere || "Not Available"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Co-applicant Name</td>
                    <td>{loan?.coApplicantName || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Co-applicant Age</td>
                    <td>{loan?.age || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Co-applicant Status</td>
                    <td>{loan?.employmentStatus || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Co-applicant Income</td>
                    <td>{loan?.incomeDetails || "Not Available"}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      Will you submit your collateral if required?
                    </td>
                    <td>
                      {loan?.willyouSubmitYourCollateral || "Not Available"}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Passport Document</td>
                    <td>
                      {loan?.uploadPassport ? (
                        <div>
                          <a
                            href={loan.uploadPassport}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-sm mx-2"
                            style={{
                              background: "#fe5722",
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                            Passport
                          </a>
                          <a
                            href={loan.uploadPassport}
                            download
                            rel="noopener noreferrer"
                            className="btn btn-sm mx-2"
                            style={{
                              background: "#fe5722",
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>{" "}
                            Passport
                          </a>
                        </div>
                      ) : (
                        "No document available"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Offer Letter</td>
                    <td>
                      {loan?.uploadOfferletter ? (
                        <div>
                          {/* View Offer Letter */}
                          <a
                            href={loan.uploadOfferletter}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-sm mx-2"
                            style={{
                              background: "#fe5722",
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                            Offer Letter
                          </a>

                          {/* Download Offer Letter */}
                          <a
                            href={loan.uploadOfferletter}
                            download
                            className="btn btn-sm mx-2"
                            style={{
                              background: "#fe5722",
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            ></i>{" "}
                            Offer Letter
                          </a>
                        </div>
                      ) : (
                        "No document available"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card border-0 rounded-1 shadow-sm p-3">
                <div className="card-body">
                  <div className="d-flex   justify-content-between align-items-center">
                    <div
                      className="position-relative m-2"
                      style={{ flex: "1 1 auto", maxWidth: "18%" }}
                    >
                      <div className="position-relative">
                        <div
                          className="progress"
                          role="progressbar"
                          aria-label="Progress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ height: "9px" }}
                        >
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            style={{
                              width: "3",
                              backgroundColor: "#000",
                            }}
                          ></div>
                        </div>

                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          style={{
                            width: "3",
                            backgroundColor: "#000",
                          }}
                        ></div>
                      </div>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>3 Days</Tooltip>}
                      >
                        <button
                          type="button"
                          className="position-absolute text-bold  top-0  start-0 translate-middle-y btn btn-sm btn-primary rounded-pill"
                          data-bs-toggle="modal"
                          data-bs-target="modal1"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            left: "0",

                            color: "#FFF",
                          }}
                        ></button>
                      </OverlayTrigger>
                      <div className="d-flex justify-content-start align-items-center mt-3">
                        {" "}
                      </div>

                      <div
                        className="modal fade"
                        id="modal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="staticBackdropLabel"
                              >
                                Application Status
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <i className="fa fa-tasks nav-icon text-dark"></i>
                                  </span>
                                  <input
                                    type="text"
                                    name="newStatus"
                                    className="form-control"
                                    placeholder="Enter Status...."
                                    aria-label="Status"
                                    aria-describedby="basic-addon1"
                                    style={{ fontSize: "12px" }}
                                  />
                                </div>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <i className="fa fa-tasks nav-icon text-dark"></i>
                                  </span>
                                  <input
                                    type="text"
                                    name="duration"
                                    className="form-control"
                                    placeholder="Enter Status...."
                                    aria-label="Status"
                                    aria-describedby="basic-addon1"
                                    style={{ fontSize: "12px" }}
                                  />
                                </div>
                                <div className="input-group mb-3">
                                  <RichTextEditor
                                    placeholder="Start writing your content here..."
                                    name="commentBox"
                                    type="text"
                                    style={{
                                      fontFamily: "Plus Jakarta Sans",
                                      fontSize: "12px",

                                      zIndex: "0",
                                    }}
                                  />
                                </div>

                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <i className="fa fa-file nav-icon text-dark"></i>
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control "
                                    style={{
                                      fontFamily: "Plus Jakarta Sans",
                                      fontSize: "12px",
                                    }}
                                    value={"80"}
                                    placeholder="Enter  Image upload"
                                    name="progress"
                                  />
                                </div>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <i className="fa fa-file nav-icon text-dark"></i>
                                  </span>
                                  <input
                                    type="file"
                                    className="form-control "
                                    style={{
                                      fontFamily: "Plus Jakarta Sans",
                                      fontSize: "12px",
                                    }}
                                    placeholder="Enter  Image upload"
                                    name="document"
                                  />
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn px-4 py-2 text-uppercase fw-semibold"
                                    data-bs-bs-dismiss="modal"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor: "#231f20",
                                      color: "#fff",
                                    }}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn px-4 py-2 text-uppercase fw-semibold"
                                    style={{
                                      fontSize: "12px",
                                      backgroundColor: "#fe5722",
                                      color: "#fff",
                                    }}
                                    data-bs-dismiss="modal"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card card-body mb-3">
                <h6 className="text-start">Notes</h6>
                <div className="text-end">
                  <button
                    className="btn btn-outline-dark text-uppercase fw-semibold px-3 py-1 text-center rounded-1"
                    data-bs-toggle="modal"
                    data-bs-target="#StatusModal"
                    style={{ fontSize: "12px" }}
                  >
                    Add Status
                  </button>
                </div>

                <div
                  className="modal fade"
                  id="StatusModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="staticBackdropLabel"
                        >
                          Application Status
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i className="fa fa-tasks nav-icon text-dark"></i>
                            </span>
                            <select
                              name="newStatus"
                              className="form-select"
                              style={{ fontSize: "12px" }}
                            >
                              <option value="">Select Status</option>
                            </select>
                          </div>
                          <div className="input-group mb-3 visually-hidden">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i className="fa fa-tasks nav-icon text-dark"></i>
                            </span>
                            <input
                              type="text"
                              name="duration"
                              value="0"
                              className="form-control"
                              placeholder="Enter Status...."
                              aria-label="Status"
                              aria-describedby="basic-addon1"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <RichTextEditor
                              placeholder="Start writing your content here..."
                              name="commentBox"
                              type="text"
                              style={{
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "12px",

                                zIndex: "0",
                              }}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <i className="fa fa-file nav-icon text-dark"></i>
                            </span>
                            <input
                              type="file"
                              className="form-control "
                              style={{
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "12px",
                              }}
                              placeholder="Enter  Image upload"
                              name="document"
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn px-4 py-2 text-uppercase fw-semibold"
                              data-bs-dismiss="modal"
                              style={{
                                fontSize: "12px",
                                backgroundColor: "#231f20",
                                color: "#fff",
                              }}
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              className="btn px-4 py-2 text-uppercase fw-semibold"
                              style={{
                                fontSize: "12px",
                                backgroundColor: "#fe5722",
                                color: "#fff",
                              }}
                              // data-bs-bs-dismiss="modal"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid my-2">
          <div className="row flex-nowrap">
            <div className="col-md-4 col-sm-4 d-flex flex-column justify-content-between overflow-auto border-end">
              <div className="card border-0 rounded-1 shadow-sm vh-100 min-vh-100 overflow-auto">
                <div className="card-header bg-white border-0">
                  <div className="text-center">
                    <button
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      className="btn btn-sm text-capitalize fw-semibold rounded-pill text-white  position-relative"
                      style={{
                        fontSize: "10px",
                        backgroundColor: "#7627ef",
                        border: "none",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#ffffff",
                          opacity: 0.2,
                        }}
                      >
                        <div
                          className="progress position-relative"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            style={{ width: "75%", height: "100%" }}
                          ></div>
                        </div>
                      </div>
                      <span>Document</span>
                    </button>
                    <div className="text-center">
                      <small>(75%) Completed</small>
                    </div>
                  </div>
                </div>
                <div className="card-body p-4">
                  <img
                    src="https://www.pngall.com/wp-content/uploads/5/Profile-Male-PNG.png"
                    className="card-img-top rounded-circle border-0"
                    alt="Profile"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <p>Application Status - submitted</p>

                  <div>
                    <a
                      href="#"
                      target="_blank"
                      className="d-flex gap-2 align-items-center mb-2 text-decoration-none"
                    >
                      <div className="me-2">
                        <img
                          src={""}
                          className="card-img-top border-0 me-2"
                          alt="Document"
                          style={{ width: "10rem", height: "3rem" }}
                        />

                        <div
                          className="card-img-top border-0 me-2 d-flex justify-content-center align-items-center"
                          style={{
                            width: "10rem",
                            height: "3rem",
                            backgroundColor: "#f0f0f0",
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{ fontSize: "12px", color: "#888" }}
                          >
                            No Document
                          </p>
                        </div>

                        <p
                          className="card-text mb-0"
                          style={{ fontSize: "12px" }}
                        ></p>
                        <div className="d-flex flex-wrap justify-content-between align-items-center mb-0 p-0">
                          <p classname="mb-0">
                            <small>Sent</small>
                          </p>

                          <p classname="mb-0">
                            <small>Time</small>
                          </p>
                        </div>
                      </div>
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-sm-8">
              <div className="card border-0 rounded-1 shadow-sm vh-100 min-vh-100 overflow-auto">
                <div className="card-header bg-white sticky-top">
                  <h6 className="card-title">Application Track</h6>
                </div>

                <div className="collapse" id="taggingSection">
                  <div
                    className="btn btns-m border-0 rounded-1 btn-danger float-end m-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#taggingSection"
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>&nbsp;Hide
                  </div>
                  <div className="card-body p-4 border rounded-1">
                    <form>
                      <div className="form-group mb-3">
                        <label for="tagPerson">Tag Person</label>
                        <input
                          type="text"
                          className="form-control rounded-1 text-muted"
                          id="tagPerson"
                          placeholder="Enter person's name"
                          style={{ fontSize: "12px" }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label for="subject">Subject</label>
                        <RichTextEditor
                          placeholder="Start writing your content here..."
                          name="content"
                          style={{
                            fontFamily: "Plus Jakarta Sans",
                            fontSize: "12px",
                          }}
                          controls={[
                            ["bold", "italic", "underline", "strike"],
                            ["h1", "h2", "h3", "h4", "h5", "h6"],
                            ["unorderedList", "orderedList"],
                            ["indent", "outdent"],
                            ["link", "image", "video"],
                            ["blockquote", "codeBlock"],
                            [
                              "alignLeft",
                              "alignCenter",
                              "alignRight",
                              "alignJustify",
                            ],
                            ["subscript", "superscript"],
                            ["color", "backgroundColor"],
                          ]}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary float-end border-0 rounded-1 "
                      >
                        Send
                      </button>
                    </form>
                  </div>
                </div>

                <div className="card-body ">
                  <div className="chat-messages">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="d-flex justify-content-end mb-4">
                          <div className="profile-content">
                            <img
                              src={
                                "https://www.pngall.com/wp-content/uploads/5/Profile-Male-PNG.png"
                              }
                              className="card-img-top rounded-circle border-0"
                              alt="Profile"
                              style={{ width: "4.5rem", height: "4.5rem" }}
                            />
                          </div>

                          <div className="col-10">
                            <div className="card ">
                              <div className="card-header text-bg-danger">
                                <p className="mb-0">Application Decision:</p>
                                <div className="d-flex gap-2">
                                  <p className="mb-0"></p>
                                  <button
                                    className="btn btn-sm btn-link text-white"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#taggingSection"
                                  >
                                    <i
                                      className="fa fa-reply"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                <RichTextEditor readOnly /> <br />
                                <br />
                                Sincerely,
                                <br />
                                <br />
                                Edufynd
                                <div className="d-flex flex-column align-items-end justify-content-end">
                                  <p className="mb-0">
                                    <b>Duration</b>- Days
                                  </p>
                                  <p className="mb-0">
                                    <b>Delayed</b>- Days
                                  </p>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-0 p-0">
                                  <p classname="mb-0">
                                    <small>Sent</small>
                                  </p>

                                  <p classname="mb-0">
                                    <small>Time</small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLoanEnquiry;
